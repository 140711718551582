import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import SectionHeader from '../../components/SectionHeader';
import Section from '../../components/Section';

const useStyles = makeStyles(theme => {
  const toolbar = theme.mixins.toolbar as any;

  return ({
    formContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: `calc(100vh - ${toolbar['@media (min-width:600px)'].minHeight}px)`,
      maxWidth: 500,
      margin: `0 auto`,
    },
    section: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    label: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
  });
});

export default function NotFound() {
  const classes = useStyles();

  const handleClick = () => {
    window.history.back();
  };

  return (
    <div>
      <Section className={classes.section}>
        <div className={classes.formContainer}>
          <SectionHeader
            label="404"
            title="Uh oh."
            subtitle={
              <span>
                Page not found.
              </span>
            }
            titleProps={{
              variant: 'h3',
            }}
            labelProps={{
              color: 'secondary',
              className: classes.label,
              variant: 'h5',
            }}
            ctaGroup={[
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={handleClick}
              >
                Go Back
              </Button>,
            ]}
            disableGutter
          />
        </div>
      </Section>
    </div>
  );
}
