import { PropsWithChildren, useCallback, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Context from './Context';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Provider({ children }: PropsWithChildren<{}>) {
  const [alert, setAlert] = useState<JSX.Element>();

  const showAlert = useCallback(({ message, severity }: ({ message: string } & AlertProps)) => {
    const handleClose = () => setAlert(undefined);

    setAlert(
      <Snackbar
        open
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={severity ?? 'success'}
        >
          { message }
        </Alert>
      </Snackbar>
    );
  }, []);

  return (
    <Context.Provider value={showAlert}>
      {children}
      {alert}
    </Context.Provider>
  );
}
